/*
    ==========================================================================
    All right reserved.
    ==========================================================================




    ==========================================================================
    TABLE OF COMTENT
    ==========================================================================

    1. Predefined variables
    2. Preloader
    3. Navbar Configuration
    4. Hero Section
    5. Countries Section
    6. Footer
    7. Masthead
    8. Custom Nav
    9. Help Card
    10. Login Designs
    11. Side Card
    12. Auth Nav
    13. Dashboard Sidebar Design
    14. Mainbody
    15. Wrapper Config
    16. Dashboard Navbar
    17. Beneficiary
    18. Name card
    19. DD Slider
    20. Beneficiary Info
    21. Customized Form Checks
    22. Pagination
    23. Profile Configuration
    24. Why Choose Us?



    ==========================================================================
    This section contains pre-defined variables and classes that would
    be used all through this project
    ==========================================================================
*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Josefin+Sans:wght@200;300;400;600;700&family=Lora:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&family=Yuji+Boku&display=swap");

:root {
  --color-red: #e91908;
  --color-red-light: #fef2f0;
  --color-red-light-2: #e9190842;
  --color-white: #fff;
  --color-dark: #140201;
  --color-gray-light: #d3d3d375;
  --color-gray-dark: var(--bs-secondary);
  --color-red-darken: #c71404;
  --color-dark-red: #140201;
  --color-white-darken: #f6f0f0;
  --color-pink: #facdc9;
  --color-light: #eeeeee;
  --color-info: #17a2b8;
  --color-brown: #4d3f3f;
  --color-brown-light: #cd7f32;
  --color-success: #008000;
  --alert-success: #00800021;
  --color-secondary: #82868b;
  --alert-secondary: #82868b21;
  --color-warning: #ffa500;
  --alert-warning: #ffa6001d;
  --rounded: 12px;
  --rounded-end-null: 12px 0 0 12px;
  --pill: 27px;
  --font-small: 14px;
  --font-normal: 600;
  --font-bold: 700;
  --dashboard-bg: #f5f5f5;
}

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "DM Sans", sans-serif;
  text-decoration: none !important;
  /* user-select: none; */
  scrollbar-width: thin;
  scrollbar-color: var(--color-dark-red) var(--color-gray-light);
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--color-gray-light);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-red);
  border-radius: 20px;
  border: 3px solid var(--color-dark-red);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-white);
}

.btn,
.form-control,
.form-select,
.navbar-toggler,
.btn-close,
.accordion-button {
  box-shadow: none !important;
  outline: none !important;
  font-size: var(--font-small);
  font-weight: var(--font-normal);
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  color: var(--color-gray-dark);
}

.form-control {
  user-select: auto;
}

.form-control::placeholder {
  font-weight: 400;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--color-gray-light);
}

.accordion-button {
  color: var(--color-dark);
}

.accordion-button:disabled {
  background-color: var(--color-white);
  opacity: 0.4;
}

.questions .accordion-button {
  padding-left: 0px;
  font-size: 16px;
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: var(--color-dark);
  background-color: var(--color-white);
}

.settings .accordion-button::after {
  filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%);
}

.min-height {
  min-height: 45px;
}

a.min-height {
  display: inline-flex;
  align-items: center;
  min-height: 45px;
}

.rounded {
  border-radius: var(--rounded) !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.input-group .input-group-text,
.dropdown-select .form-control {
  border-radius: 8px 0 0 8px;
}

.input-group .input-group-text.end,
.dropdown-select .form-control.end {
  border-radius: 0 8px 8px 0;
}

.input-group.floating-group {
  height: 50px !important;
}

.input-group .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-file-upload .file-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  border: 1px solid var(--color-gray-light);
  background: #edf4fb;
  border-radius: 8px;
}

input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.rounded-end-0 {
  border-radius: 12px 0 0 12px !important;
}

.pill {
  border-radius: var(--pill) !important;
}

td,
th {
  white-space: nowrap;
  vertical-align: middle;
}

.bg-red {
  background: var(--color-red);
}
.bg-red-light{
  background: var(--color-red-light);
} 

.text-red,
.text-red:hover {
  color: var(--color-red);
}

.text-light-red,
.text-light-red:hover {
  color: var(--color-red-light-2);
}

.text-brown {
  color: var(--color-brown-light);
}

.text-dark {
  color: var(--color-dark) !important;
}

.btn-white {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border-color: var(--color-gray-light) !important;
}

.btn-white:hover {
  background-color: var(--color-white-darken) !important;
  border-color: var(--color-white-darken) !important;
}

.status-width {
  display: inline-block;
  width: 130px;
  text-align: center;
}

.btn-red {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}

.btn-outline-red {
  border-color: var(--color-red) !important;
  color: var(--color-red) !important;
}

.btn-outline-red:hover {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}

.btn-red:hover {
  background-color: var(--color-red-darken) !important;
  border-color: var(--color-red-darken) !important;
}

.alert-danger {
  color: var(--color-red);
  background-color: #facdc9 !important;
}

.alert-secondary {
  color: var(--color-secondary);
  border-color: var(--alert-secondary);
  background-color: var(--alert-secondary);
}

.alert-success {
  color: var(--color-success);
  border-color: var(--alert-success);
  background-color: var(--alert-success);
}

.alert-warning {
  color: var(--color-warning);
  border-color: var(--alert-warning);
  background-color: var(--alert-warning);
}

.section {
  padding: 80px 0;
}

.header {
  font-size: 61px;
}

.header-2 {
  font-size: 54px;
}

.header-3 {
  font-size: 48px;
}

.header-4 {
  font-size: 36px;
}

.fw-bold {
  font-weight: var(--font-normal) !important;
}

.bg-linear-pink {
  background: linear-gradient(to top, #fa433259, #ffffff);
}

.bg-linear-red {
  background: linear-gradient(to right, #000, #530d06);
}

.bg-pink {
  background-color: var(--color-pink);
}

p,
a,
label,
.alert {
  font-size: var(--font-small);
}

.bg-light {
  background-color: var(--color-light) !important;
}

.bg-red-light {
  background-color: var(--color-red-light) !important;
}

.text-info {
  color: var(--color-info) !important;
}

.text-success {
  color: var(--color-success) !important;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link.active {
  color: var(--color-dark) !important;
}

/* ========================  
    2. P R E L O A D E R  
========================== */
#preloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

#preloader .progress {
  height: 5px !important;
  width: 400px !important;
}

#preloader.loaded {
  opacity: 1;
  z-index: 99999;
  animation-name: fadeout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

/* A M I N A T I O N */
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -9999;
  }
}

@media screen and (max-width: 576px) {
  /* mobile configuration for preloader */
  #preloader .progress {
    height: 5px !important;
    width: 280px !important;
  }
}

/* ==================================================  
    3. N A V B A R     C O N F I G U R A T I O N
================================================== */
.navbar {
  transition: 0.2s all ease-in-out;
}

.nav-item {
  padding: 0 7px;
}

.nav-link {
  font-weight: var(--font-normal);
  font-size: var(--font-small);
  color: var(--color-dark) !important;
}

.nav-link:hover,
.nav-link.active {
  color: var(--color-red) !important;
}

/* ==================================================  
    4. H E R O     S E C T I O N
================================================== */
.hero {
  position: relative;
  background: linear-gradient(to left bottom, #fa433259, #ffffff);
  min-height: 700px;
}

.hero .hero-content {
  position: relative;
  padding-top: 200px;
}

.hero .hero-content h1 {
  font-size: 72px;
  font-weight: 600 !important;
}

.hero .hero-content .badge {
  padding: 8px 10px;

}

.hero .hero-bg-content .hero-map {
  position: absolute;
  filter: invert(1);
  opacity: 0.1;
  width: 100vw;
  top: 0px;
  left: 0px;
}

.hero .hero-bg-content .hero-vector-1 {
  position: absolute;
  filter: brightness(0) invert(1);
  top: 0px;
  right: 0px;
  opacity: 0.5;
  height: 600px;
}

.hero .hero-bg-content .hero-vector-2 {
  position: absolute;
  filter: brightness(0) invert(1);
  top: 80px;
  right: 0px;
  opacity: 0.5;
  height: 550px;
}

.hero .hero-rectangle {
  position: absolute;
  filter: brightness(0) invert(1);
  height: 450px;
  width: 700px;
  top: -20px;
  right: -35px;
  opacity: 0.5;
}

.hero-card-details li {
  position: relative;
}

.hero-card-details li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color-red);
  border-radius: 50%;
  left: 0px;
  top: 13px;
}

.hero-card-details li::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: var(--color-red);
  left: 4.5px;
  top: 15px;
}

.hero-card-details li:last-of-type:after {
  display: none;
}

.hero-content-card {
  position: absolute;
  width: 100%;
  top: 10px;
}

@media screen and (max-width: 1200px) {
  .hero .hero-content {
    position: relative;
    /* padding-top: 150px; */
  }
}

@media screen and (max-width: 991px) {
  .hero-content-card {
    position: relative;
  }

  .hero .hero-content {
    padding-top: 80px;
  }

  .hero .hero-content h1 {
    font-size: 32px;
    font-weight: 600 !important;
  }
}

/* ==================================================  
    5. C O U N T R I E S      S E C T I O N 
================================================== */
.countries {
  background: url("../images/components/Frame\ 52.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ==================================================  
    6. F O O T E R   
================================================== */
.footer-img {
  filter: brightness(0) invert(1);
  opacity: 0.8;
}

/* ==================================================  
    7. M A S T H E A D 
================================================== */
.masthead {
  position: relative;
  background: linear-gradient(to top, #fa433259, #ffffff);
  min-height: 400px;
  /* overflow-x: hidden; */
}

.masthead .masthead-content {
  position: relative;
  padding-top: 150px;
  padding-bottom: 10px;
}

.masthead-bg-content {
  position: relative;
  overflow-x: hidden;
}

.masthead-bg-content .masthead-bg {
  position: absolute;
  filter: brightness(0) invert(1);
  opacity: 0.3;
}

.masthead-bg-content .masthead-bg-1 {
  bottom: 0px;
  left: 200px;
}

.masthead-bg-content .masthead-bg-2 {
  bottom: 0px;
  right: -120px;
}

.masthead-bg-content .masthead-bg-3 {
  bottom: 0px;
  right: 0px;
}

/* ==================================================  
    8. C U S T O M    N A V
================================================== */

.custom-nav .nav-item {
  margin-right: 10px;
}

.custom-nav .nav-item .nav-link {
  padding: 0px;
  padding: 5px;
  border-radius: 0%;
  text-transform: uppercase;
  font-weight: 400;
}

.custom-nav .nav-item .nav-link.active {
  background: transparent !important;
  border-bottom: 1px solid var(--bs-dark);
  font-weight: 600;
}

/* ==================================================  
    9. H E L P     C A R D
================================================== */
.help-card {
  position: relative;
  transition: 0.2s all ease-in-out;
  height: 210px;
}

.help-card:hover {
  border-color: var(--color-red);
  color: var(--color-red);
  box-shadow: 0px 3px 10px 8px var(--color-red-light);
}

/* .help-icon-red {
    display: none;
}
.help-card:hover .help-icon{
    display: none;
}
.help-card:hover .help-icon-red{
    display: inline-block;
} */

/* ==================================================  
    10. L O G I N    D E S I G N S
================================================== */
.login-section {
  position: relative;
  padding: 150px 0;
}

.login-section .row {
  z-index: 1;
}

.login-design {
  position: fixed;
  opacity: 0.8;
  width: 300px;
}

.login-design-1 {
  top: 0px;
  right: 0px;
}

.login-design-2 {
  bottom: 0px;
  left: 0px;
}

@media screen and (max-width: 991px) {
  .login-design {
    width: 200px;
  }
}

/* ==================================================  
    11. S I D E    C A R D
================================================== */
.side-card {
  position: relative;
  width: 400px;
  height: 100vh;
}

.side-card .text-center {
  position: relative;
  z-index: 4;
}

.side-card-bg {
  position: absolute;
}

.side-card-bg-1 {
  bottom: 0px;
  left: 0px;
  width: 250px;
  z-index: 2;
  opacity: 0.9;
}

.side-card-bg-2 {
  bottom: 0px;
  left: 50px;
  width: 230px;
  filter: brightness(0) invert(1);
  opacity: 0.7;
  z-index: 1;
}

/* ==================================================  
    11. S I D E    C A R D
================================================== */
.auth-mainbody {
  position: relative;
  padding-left: 400px;
}

@media screen and (max-width: 1200px) {
  .auth-mainbody {
    padding-left: 0px;
  }
}

/* ==================================================  
    12. A U T H     N A V
================================================== */
.auth-nav {
  justify-content: center;
  margin-bottom: 20px;
}

.auth-nav .nav-box {
  display: inline-flex;
  height: 45px;
  width: 45px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-gray-light);
  margin-right: 10px;
}

.auth-nav .nav-text {
  color: var(--color-gray-dark);
}

.auth-nav .nav-link.active .nav-text,
.auth-nav .nav-link.done .nav-text {
  color: var(--color-dark);
}

.auth-nav .nav-link.active .nav-box {
  background-color: var(--color-red);
}

.auth-nav .nav-link.done .nav-box {
  background-color: var(--color-pink);
  color: var(--color-dark);
}

/* ===========================================================  
    13. D A S H B O A R D    S I D E B A R    D E S I G N
============================================================== */
.sidebar {
  position: fixed;
  height: 100vh;
  width: 250px;
  background-color: var(--color-white);
  top: 0px;
  left: 0px;
  padding: 20px;
  z-index: 1000;
  transition: 0.2s all ease-in-out;
}

.sidebar .sidebar-header {
  position: relative;
  margin-bottom: 40px;
}

.sidebar .sidebar-logo-half {
  display: none;
}

.sidebar .sidebar-icon-open {
  display: none;
}

.sidebar .sidebar-toggle-btn {
  position: absolute;
  height: 27px;
  width: 27px;
  top: 7px;
  right: -33px;
  background: var(--color-red-light);
  color: var(--color-red);
  border: 1px solid var(--color-red-light);
  border-radius: 50%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-nav .sidebar-link {
  display: flex;
  align-items: center;
  padding: 12px;
  color: var(--color-dark);
  font-size: var(--font-small);
  white-space: nowrap;
}

.sidebar-nav .sidebar-link.active {
  background-color: var(--color-red-light);
  color: var(--color-red);
  border-radius: 12px;
  font-weight: var(--font-normal);
}

.sidebar-nav .sidebar-link .sidebar-icon {
  display: inline-flex;
  width: 50px;
  justify-content: center;
  text-align: center;
}

.sidebar-icon-2 {
  display: none;
}

.sidebar-nav .sidebar-link.active .sidebar-icon-2 {
  display: block;
}

.sidebar-nav .sidebar-link.active .sidebar-icon-1 {
  display: none;
}

.sidebar-nav .sidebar-item:last-of-type {
  position: absolute;
  bottom: 150px;
}

@media screen and (max-width: 991px) {
  .sidebar {
    left: -251px;
  }

  .sidebar .sidebar-toggle-btn {
    right: -65px;
  }

  .sidebar .sidebar-icon-open {
    display: block;
  }

  .sidebar .sidebar-icon-close {
    display: none;
  }

  .sidebar-nav .sidebar-item:last-of-type {
    position: relative;
    bottom: 0px;
  }
}

/* ===========================================================  
    14. M A I N B O D Y
============================================================== */
.mainbody {
  position: absolute;
  padding-left: 250px;
  top: 0px;
  left: 0px;
  background-color: var(--dashboard-bg);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  transition: 0.2s all ease-in-out;
}

@media screen and (max-width: 991px) {
  .mainbody {
    padding: 0px;
  }
}

/* ===========================================================  
    15.  W R A P P E R     C O N F I G
============================================================== */
#wrapper.open .sidebar {
  width: 100px;
}

#wrapper.open .sidebar .sidebar-link .sidebar-text {
  display: none;
}

#wrapper.open .sidebar .sidebar-link .sidebar-icon {
  width: 100%;
}

#wrapper.open .sidebar .sidebar-logo-full {
  display: none;
}

#wrapper.open .sidebar .sidebar-logo-half {
  display: block;
}

#wrapper.open .sidebar .sidebar-icon-open {
  display: block;
}

#wrapper.open .sidebar .sidebar-icon-close {
  display: none;
}

#wrapper.open .sidebar-nav .sidebar-item:last-of-type {
  left: 25px;
}

#wrapper.open .mainbody {
  padding-left: 100px;
}

@media screen and (max-width: 991px) {
  #wrapper.open .sidebar {
    left: 0px;
    width: 250px;
  }

  #wrapper.open .sidebar .sidebar-toggle-btn {
    right: -33px;
  }

  #wrapper.open .sidebar .sidebar-link .sidebar-text {
    display: inline-block;
  }

  #wrapper.open .sidebar .sidebar-link .sidebar-icon {
    width: 50px;
  }

  #wrapper.open .sidebar .sidebar-logo-full {
    display: block;
  }

  #wrapper.open .sidebar .sidebar-logo-half {
    display: none;
  }

  #wrapper.open .sidebar .sidebar-icon-open {
    display: none;
  }

  #wrapper.open .sidebar .sidebar-icon-close {
    display: block;
  }

  #wrapper.open .sidebar-nav .sidebar-item:last-of-type {
    left: 0px;
  }

  /* side bar overlay */
  .sidebar-overlay {
    position: absolute;
    z-index: -9000;
    height: 100vh;
    width: 100vw;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in-out;
    opacity: 0;
  }

  #wrapper.open .sidebar-overlay {
    opacity: 1;
    z-index: 999;
    position: fixed;
  }

  #wrapper.open .mainbody {
    padding-left: 0px;
  }
}

/* ===========================================================  
    16. D A S H B O A R D     N A V B A R
============================================================== */
.mainbody .navbar .nav-link.btn {
  width: 40px;
}

.mainbody .navbar .nav-item .dropdown-menu {
  top: 45px;
  left: -140px;
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item {
  padding: 15px;
  border-bottom: 1px solid var(--color-gray-light);
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item span {
  display: inline-block;
  text-align: center;
  justify-content: center;
  width: 25px;
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item:active,
.mainbody .navbar .nav-item .dropdown-menu .dropdown-item.active {
  background-color: var(--color-red-light);
  color: var(--color-red);
}

/* =====================================================  
    17. B E N E F I C I A R I E S
======================================================== */
.form-check {
  position: relative;
}

.beneficiary-item .form-check {
  display: flex;
  justify-content: center;
  padding: 0px !important;
}

.beneficiary-item .form-check-input {
  position: absolute;
  top: 50%;
  left: 50%;
}

.beneficiary-item
  .form-check-input:checked
  ~ .form-check-label
  .beneficiary-card {
  border: 1px solid var(--color-red) !important;
}

.beneficiary-list {
  width: 470px;
  max-height: 470px;
  overflow-y: auto;
}

.beneficiary-list .dropdown-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.beneficiary-list .dropdown-item:active {
  background-color: var(--color-gray-light);
}
.beneficiary-select-container
  > .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none;
  box-shadow: 0 0 0 0;
}
.beneficiary-select .ant-select-selector .ant-select-selection-item,
.beneficiary-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 26px !important;
  font-weight: 600;
}
.beneficiary-select-container .ant-select-selector {
  height: 40px;
}
.beneficiary-select
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-red-light);
}
.beneficiary-select .ant-select-arrow {
  display: none;
}
.ant-segmented-item-selected {
  box-shadow: 0 2px 8px -2px rgb(0 0 0 / 1%), 0 1px 4px -1px rgba(0, 0, 0, 0.01),
    0 0 1px 0 rgba(0, 0, 0, 0.01);
}

.ant-select-dropdown {
  z-index: 1200 !important;
}
@media screen and (max-width: 470px) {
  .beneficiary-list {
    width: 95vw;
  }
  .ant-notification {
    z-index: 999999 !important;
  }
  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 1100 !important;
  }
}

/* =====================================================  
    18. N A M E     C A R D
======================================================== */
.name-card {
  height: 40px;
  width: 40px;
  background-color: var(--color-red-light);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* =================================  
    19. D D - S L I C K
==================================== */
.dropdown-select .dropdown-menu {
  max-height: 410px;
  overflow-y: auto;
}

.dropdown-select .input-group {
  border-radius: var(--rounded) !important;
  border-width: 0px;
}

.dropdown-select .dropdown-menu .input-group-text {
  border-radius: var(--rounded) 0 0 var(--rounded) !important;
}

.dropdown-select .dropdown-menu .form-control {
  background-color: #e9ecef;
  border-radius: 0 var(--rounded) var(--rounded) 0 !important;
}

.dropdown-select .dropdown-item:active,
.dropdown-select .dropdown-item:hover {
  background-color: var(--color-red-light);
  color: var(--color-red);
}

.dropdown-select .dropdown-toggle::after {
  margin-top: 10px;
  float: right;
}

/* =============================================  
    20. B E N E F I C I A R Y     I N F O
================================================= */
.beneficiary-info {
  display: none;
}

.beneficiary-info.active {
  display: block;
}

/* =======================================================  
    21. C U S T O M I Z E D     F O R M    C H E C K S
========================================================== */
.form-check .form-check-input:checked {
  background-color: var(--color-red);
  border-color: var(--color-red);
}

.form-check .form-check-input[type="radio"] {
  float: right;
  margin-left: 0;
}

.form-check .form-check-label.adjust {
  margin-left: -20px;
}

/* =============================================  
    22. P A G I N A T I O N
================================================= */
.pagination {
  border: none;
}

.pagination .page-item {
  padding: 0px 4px;
}

.pagination .page-item.disabled .page-link {
  background-color: var(--alert-secondary);
}

.pagination .page-item.active .page-link {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);
}

.pagination .page-link {
  border-radius: 8px !important;
  color: var(--color-dark);
}

/* ====================================================  
    23. P R O F I L E     C O N F I G U R A T I O N
======================================================= */
.profile-image {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--color-brown);
  display: flex;
  overflow: hidden;
}

.profile-image .profile-name-tag {
  display: block;
  color: var(--color-white);
  margin: auto;
  font-size: 22px;
  font-weight: var(--font-bold);
}

.profile-img-tag {
  position: absolute;
  top: 0px;
  left: 0px;
}

/* ====================================================  
    24. W H Y     C H O O S E     U S ?
======================================================= */
.why-choose {
  position: relative;
  background: var(--color-white);
  transition: 0.2s all ease-in-out;
  overflow: hidden;
}

.why-choose .bg-icon,
.why-choose .card-icon-red {
  display: none;
}

.why-choose:hover {
  background: linear-gradient(to top, #fa433259, #ffffff);
  box-shadow: 0px 10px 10px 5px var(--color-gray-light);
}

.why-choose:hover .header-2 {
  color: var(--color-red);
}

.why-choose:hover .bg-icon {
  top: -95px;
  right: 30px;
  display: block;
}

.why-choose:hover .card-icon-red {
  display: block;
}

.why-choose:hover .card-icon {
  display: none;
}

a.link {
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  .why-choose {
    border: 1px solid var(--color-light) !important;
  }
}

/* DROPDOWN SEARCH */
.dropdown-search {
  display: block;
  padding: 0px 15px;
  margin-bottom: 25px;
}

.dropdown-search .input-group .form-control {
  background-color: #e9ecef;
}

.search-input {
  position: relative;
}

.search-input .search-suggestions {
  position: absolute;
  display: none;
  top: 52px;
  width: 100%;
  max-height: 190px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 0 0 12px 12px;
  overflow-y: auto;
  transition: 0.2 all ease-in-out;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}

.search-input.active .input-group .input-group-prepend .input-group-text {
  border-radius: 14px 0 0 0 !important;
}

.search-input.active .input-group .input-group-append .input-group-text {
  border-radius: 0 14px 0 0 !important;
}

.search-input.active .search-suggestions {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.search-input .search-suggestions .suggestion-item {
  position: relative;
  display: block;
  margin: 0 10px;
  border-radius: 12px;
  padding: 10px 20px;
  transition: 0.2 all ease-in-out;
  background-color: #fff;
}

.search-input .search-suggestions .suggestion-item:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

footer {
  background: var(--color-dark-red);
  color: var(--color-white);
}

.footer-brand span {
  display: block;
  margin-bottom: 0px;
  font-size: 24px;
}

.send-money-btn {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 144px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px 0 24px 0;
  background: linear-gradient(89.67deg, #140201 -302.46%, #e91908 95.85%);
  color: var(--color-white);
  height: 52px;
  white-space: nowrap;
  transition: 0.2s all ease-in-out;
}

.send-money-img {
  margin-bottom: 7px;
}

.send-money-btn:hover .send-money-img {
  margin-right: 5px;
}

.send-money-title {
  font-size: 24px;
}

.send-money-tab {
  display: none;
}

.send-money-tab.active {
  display: block;
}

/* upload level */
.upload-level {
  background-repeat: no-repeat;
  background-size: cover;
}

.upload-level .card-title {
  font-size: 20px;
}

.upload-level-one {
  background-image: url("../images/components/trianglify-lowres 1.png");
}

.upload-level-two {
  background-image: url("../images/components/trianglify-lowres1.png");
}

.upload-level-three {
  background-image: url("../images/components/trianglify-lowres2.png");
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 2.5rem;
  /* align-items: center; */
  margin-top: 0.47rem;
}
