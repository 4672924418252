.custom-dropdown .ant-select-item-option-content {
  display: flex;
  align-items: center;
  
}

.option-label {
  margin-right: 8px;
}

.option-count {
  /* width: 24px; */
  /* height: 24px; */
  /* border-radius: 50%;  */
  background-color: grey;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
}
