:root {
	--check-size: 100px;
	--check-border-width: 5px;
	--checkmark-width: calc(var(--check-size) / 2);
	--checkmark-height: calc(var(--checkmark-width) / 2);
	--checkmark-left: calc(var(--checkmark-width) / 2);
	--checkmark-top: 50%;
	--checkmark-color: white;
}

.successful__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.succeess__i_tag {
	margin-bottom: 2rem;
}

.icon_round {
	width: 150px;
	aspect-ratio: 1/1;
	position: relative;
	margin: auto auto 10px auto;
}

.icon_round::after {
	content: "";
	position: absolute;
	top: 20px;
	left: 20px;
	/* inset: 0; instead of setting a top, left, right, buttom */
	border: var(--check-border-width) solid #f8f8f8;
	width: 80%;
	height: 80%;
	border-radius: 50%;
	display: block;
	z-index: 0;
	background: rgb(32, 182, 32);
}

.icon_round::before {
	content: "";
	position: absolute;
	top: 20px;
	left: 20px;
	/* inset: 0; instead of setting a top, left, right, buttom */
	border: var(--check-border-width) solid transparent;
	border-left-color: rgb(32, 182, 32);
	width: 80%;
	height: 80%;
	border-radius: 50%;
	display: block;
	z-index: 1;
	animation: Circle linear forwards 0.75s;
}

.icon_check {
	height: var(--checkmark-height);
	width: var(--checkmark-width);
	position: absolute;
	opacity: 0;
	left: 55px;
	top: 55%;
	display: block;
	border-left: var(--check-border-width) solid white;
	border-bottom: var(--check-border-width) solid white;
	transform-origin: left top;
	transform: rotate(-45deg);
	animation: checkmark linear both 1s;
	z-index: 99;
}

@keyframes Circle {
	0% {
		border-color: transparent;
		border-left-color: rgb(32, 182, 32);
		opacity: 1;
	}

	90% {
		transform: rotate(360deg);
		border-color: transparent;
		border-left-color: rgb(32, 182, 32);
		opacity: 1;
	}

	100% {
		transform: rotate(-360deg);
		border-color: transparent;
		border-color: rgb(32, 182, 32);
		/* opacity: 1; */
		opacity: 0;
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 0;
	}

	80% {
		height: 0;
		width: 0;
		opacity: 0;
	}

	90% {
		height: var(--checkmark-height);
		width: 0;
		opacity: 1;
	}

	100% {
		height: var(--checkmark-height);
		width: var(--checkmark-width);
		opacity: 1;
	}
}
/* CHECK MARK */

.succeess__i_tag > h4 {
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 0px;
}

.succeess__i_tag > small {
	font-size: 10px;
	color: black;
	font-family: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	margin: auto;
}

/* paragraph */

.paragraph_info_p {
	background: rgb(255, 248, 248);
	padding: 20px 20px;
}

.paragraph_info_p > p {
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 0px;
}
/* BUTTON */

.BUTTON_btn_success {
	width: 100%;
	margin-top: 0.5rem;
}

.btn__submit_success {
	width: 95%;
	border-radius: 8px;
	border: 0px;
	background: red;
	color: white;
	padding: 1rem 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}
