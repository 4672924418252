.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 45px !important;
  transition: all 0.3s;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 45px !important;
  /* padding: 5px 11px !important; */
}

.ant-modal-content {
  border-radius: 15px;
  /* background: red; */
}

.ant-modal-content {
  border-radius: 15px;
  /* background: red; */
}

.ant-modal-header {
  border-radius: 15px 15px 0 0;
}

.ant-spin-dot-item {
  background-color: #777171;
}

.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: white;
  border-radius: 100px;
}

