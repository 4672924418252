p {
	margin-top: 0;
	margin-bottom: 0;
}

/* paragraph */
.only__AUD_Paragraph {
	background: rgb(235, 235, 245);
	display: flex;
	border-radius: 8px;
	padding: 12px 0px 12px 14px;
}

.only__AUD_Paragraph > small {
	display: flex;
	margin-bottom: 0px;
	font-weight: 500;
}

.only__AUD_Paragraph > small > span {
	margin-left: 3px;
	margin-right: 3px;
	font-weight: bold;
}

/* selection */

.SELECTION_OPTION {
	display: flex;
	/* flex-direction: column; */
	justify-content: space-between;
	border: 1px solid #d9d6d6;
	border-radius: 8px;
	margin-top: 1rem;
	padding: 10px 10px 20px;
	position: relative;
}

.SELECTION_OPTION > .option_small____ {
	padding-left: 5px;
	font-size: 10px;
}

.SELECTION_OPTION select {
	border: 0px;
	outline: 0px;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	margin-top: 3px;
	padding-left: 3px;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	right: 0px;
	padding-left: 15px;
	padding-top: 30px;
	padding-bottom: 5px;
	background: transparent;
	/* display: none; */
}

.Arrow_dropdowm {
	position: "absolute";
	right: "0px";
	top: "17px";
	font-size: "17px";
}

.SELECTION_OPTION select option {
	font-size: 1rem;
}

.span_fee {
	font-size: 11;
}

._p__fee {
	font-weight: 500;
}

/* NUMBER INPUT */

.NUMBER__INPUT {
	display: flex;
	margin-top: 1rem;
	border: 1px solid #d9d6d6;
	border-radius: 8px;
	padding: 0px 0px 0px 10px;
	height: 60px;
}

.input___img_aud {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.input___img_aud > small {
	font-size: 10px;
}

/* remover of the up and down arrow in the input */
.input___img_aud input[type="number"]::-webkit-inner-spin-button,
.input___img_aud input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

#input-numb {
	display: flex;
	height: 30px;
	border: 0px;
	outline: 0px;
	font-size: 20px;
	padding-right: 10px;
}

.AUD_image_aud {
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 2px solid #d9d6d6;
}

.AUD_image_aud > p {
	margin-bottom: 0px;
	padding-left: 5px;
}

.AUD_image_aud > img {
	width: 20px;
	border-radius: 2px;
}

/* Charge_fee */

.Charge_fee {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	border-radius: 8px;
	border: 0px;
	outline: 0px;
	padding: 15px 15px;
	background: rgb(255, 250, 250);
}

.dt_fe {
	display: flex;
	align-items: center;
}

.dot__ {
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: red;
	margin-right: 0.5rem;
}

/* error essage */
.error__message {
	display: flex;
	margin-top: 1rem;
}

.err__icon {
	color: red;
	margin-right: 1rem;
	font-size: 1.3rem;
}

.err__info {
	font-size: 14px;
	color: red;
	margin-bottom: 0px;
	line-height: 1.3;
	padding: 0px;
}

/* BUTTTON */
.BUTTON_btn {
	width: 100%;
	margin-top: 2.5rem;
}

.btn__submit {
	width: 95%;
	border-radius: 8px;
	border: 0px;
	background: red;
	color: white;
	padding: 1rem 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

@media screen and (max-width: 600px) {
	.only__AUD_Paragraph > small > span {
		width: max-content;
		text-wrap: nowrap;
		margin-left: 3px;
		margin-right: 3px;
		font-weight: bold;
	}
}

@media screen and (max-width: 300px) {
	.only__AUD_Paragraph {
		background: rgb(235, 235, 245);
		display: flex;
		border-radius: 8px;
		padding: 12px 0px 12px 5px;
	}

	.only__AUD_Paragraph > small {
		display: flex;
		margin-bottom: 0px;
		font-weight: 500;
		text-wrap: nowrap;
		width: 100%;
	}

	.only__AUD_Paragraph > small > span {
		width: max-content;
		text-wrap: nowrap;
		margin-left: 3px;
		margin-right: 3px;
		font-weight: bold;
	}
}
