/*
    ==========================================================================
    All right reserved.
    ==========================================================================




    ==========================================================================
    TABLE OF CONTENT
    ==========================================================================

    1. Predefined variables
    2. Preloader
    3. Navbar Configuration
    4. Hero Section
    5. Countries Section
    6. Footer
    7. Masthead
    8. Custom Nav
    9. Help Card
    10. Login Designs
    11. Side Card
    12. Auth Nav
    13. Dashboard Sidebar Design
    14. Mainbody
    15. Wrapper Config
    16. Dashboard Navbar
    17. Beneficiary
    18. Name card 
    19. DD Slider
    20. Beneficiary Info
    21. Customized Form Checks
    22. Pagination
    23. Profile Configuration
    24. Why Choose Us?
    25. Privacy Policy



    ==========================================================================
    This section contains pre-defined variables and classes that would
    be used all through this project
    ==========================================================================
*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Josefin+Sans:wght@200;300;400;600;700&family=Lora:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&family=Yuji+Boku&display=swap");

:root {
  --color-red: #e91908;
  --color-red-light: #fef2f0;
  --color-red-light-2: #e9190842;
  --color-red-light-3: #f6bfbb;
  --color-white: #fff;
  --color-dark: #140201;
  --color-gray-light: #d3d3d375;
  --color-gray-dark: var(--bs-secondary);
  --color-red-darken: #c71404;
  --color-dark-red: #140201;
  --color-white-darken: #f6f0f0;
  --color-pink: #facdc9;
  --color-light: #eeeeee;
  --color-info: #17a2b8;
  --color-brown: #4d3f3f;
  --color-brown-light: #cd7f32;
  --color-success: #008000;
  --alert-success: #00800021;
  --color-secondary: #82868b;
  --alert-secondary: #82868b21;
  --color-warning: #ffa500;
  --alert-warning: #ffa6001d;
  --rounded: 12px;
  --rounded-end-null: 12px 0 0 12px;
  --pill: 27px;
  --font-small: 14px;
  --font-normal: 600;
  --font-bold: 700;
  --dashboard-bg: #f5f5f5;
}

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "DM Sans", sans-serif;
  text-decoration: none !important;
  /* user-select: none; */
  scrollbar-width: thin;
  scrollbar-color: var(--color-dark-red) var(--color-gray-light);
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: var(--color-gray-light);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-red);
  border-radius: 20px;
  border: 3px solid var(--color-dark-red);
}

html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--color-white);
}
.btn,
.form-control,
.form-select,
.navbar-toggler,
.btn-close,
.accordion-button {
  box-shadow: none !important;
  outline: none !important;
  font-size: var(--font-small);
  font-weight: var(--font-normal);
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  color: var(--color-gray-dark);
}
.form-control {
  user-select: auto;
}
.form-control::placeholder {
  font-weight: 400;
}
.form-control:focus,
.form-select:focus {
  border-color: var(--color-gray-light);
}
.accordion-button {
  color: var(--color-dark);
}
.accordion-button:disabled {
  background-color: var(--color-white);
  opacity: 0.4;
}
.questions .accordion-button {
  padding-left: 0px;
  font-size: 16px;
  font-weight: 500;
}
.accordion-button:not(.collapsed) {
  color: var(--color-dark);
  background-color: var(--color-white);
}
.settings .accordion-button::after {
  filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%);
}
.min-height {
  min-height: 45px;
}
a.min-height {
  display: inline-flex;
  align-items: center;
  min-height: 45px;
}
.export-container {
  padding-top: 2px;
  padding-bottom: 2px;
}
.text-Monova {
  color: var(--color-brown-light);
}
.text-Novatti {
  color: "#7d56c0";
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-red-light);
}
.text-white {
  color: white !important;
}
.text-white:hover {
  color: white !important;
}
.rounded {
  border-radius: var(--rounded) !important;
}
.rounded-0 {
  border-radius: 0px !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.input-group .input-group-text,
.dropdown-select .form-control {
  border-radius: 8px 0 0 8px;
}
.input-group .input-group-text.end,
.dropdown-select .form-control.end {
  border-radius: 0 8px 8px 0;
}
.input-group.floating-group {
  height: 50px !important;
}

.input-group .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-file-upload .file-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  border: 1px solid var(--color-gray-light);
  background: #edf4fb;
  border-radius: 8px;
}
input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.rounded-end-0 {
  border-radius: 12px 0 0 12px !important;
}
.pill {
  border-radius: var(--pill) !important;
}
td,
th {
  white-space: nowrap;
  vertical-align: middle;
}
.bg-red {
  background: var(--color-red);
}
.text-red,
.text-red:hover {
  color: var(--color-red);
}
.text-light-red,
.text-light-red:hover {
  color: var(--color-red-light-2);
}
.text-brown {
  color: var(--color-brown-light);
}
.text-dark {
  color: var(--color-dark) !important;
}
.high {
  color: var(--color-red);
}
.medium {
  color: var(--color-warning);
}
.low {
  color: var(--color-success);
}
.unknown {
  color: var(--color-brown-light);
}
.btn-white {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border-color: var(--color-gray-light) !important;
}
.btn-white:hover {
  background-color: var(--color-white-darken) !important;
  border-color: var(--color-white-darken) !important;
}
.ant-switch-checked {
  background-color: var(--color-red) !important;
}
.status-width {
  display: inline-block;
  width: 130px;
  text-align: center;
}
.btn-red {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}
.btn-outline-red {
  border-color: var(--color-red) !important;
  color: var(--color-red) !important;
}
.underline {
  text-decoration: underline !important;
}
.btn-outline-red:hover {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}
.btn-red:hover {
  background-color: var(--color-red-darken) !important;
  border-color: var(--color-red-darken) !important;
}
.alert-danger {
  color: var(--color-red);
  background-color: #facdc9 !important;
}
.alert-secondary {
  color: var(--color-secondary);
  border-color: var(--alert-secondary);
  background-color: var(--alert-secondary);
}
.alert-success {
  color: var(--color-success);
  border-color: var(--alert-success);
  background-color: var(--alert-success);
}
.alert-warning {
  color: var(--color-warning);
  border-color: var(--alert-warning);
  background-color: var(--alert-warning);
}
.section {
  padding: 80px 0;
}
.header {
  font-size: 61px;
}
.header-2 {
  font-size: 54px;
}
.header-3 {
  font-size: 48px;
}
.header-4 {
  font-size: 36px;
}
.fw-bold {
  font-weight: var(--font-normal) !important;
}
.bg-linear-pink {
  background: linear-gradient(to top, #fa433259, #ffffff);
}
.bg-linear-red {
  background: linear-gradient(to right, #000, #530d06);
}
.bg-pink {
  background-color: var(--color-pink);
}
p,
a,
label,
.alert {
  font-size: var(--font-small);
}
.bg-light {
  background-color: var(--color-light) !important;
}
.bg-red-light {
  background-color: var(--color-red-light) !important;
}
.text-info {
  color: var(--color-info) !important;
}
.text-success {
  color: var(--color-success) !important;
}
.nav-pills .nav-link:hover,
.nav-pills .nav-link.active {
  color: var(--color-dark) !important;
}
.bg-red-fade {
  background-color: var(--color-red-light-3);
}
.py-small {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
.fw-medium {
  font-weight: 500;
}
.sendOptionActive {
  background-color: white;
  border-radius: 0.25rem;
  padding: 10px 20px;
  color: var(--color-red);
}
.swiper_container {
  background-color: #fffafa;
}
.swiper .ant-segmented-group {
  justify-content: space-between;
  /* background-color: transparent; */
}
.swiper .ant-segmented-item {
  border-radius: 0.25rem;
  padding: 5px 10px;
}
.swiper .ant-segmented-item-label {
  font-weight: 700;
}
.swiper .ant-segmented-item-selected {
  color: var(--color-red);
}
.new-feature {
  top: -2px;
}
.wallet_table_wrapper {
  background-color: #eeeeee;
  border-radius: 1rem;
}
.wallet_info_container {
  grid-column: span 2;
}
.wallet_select {
  background-color: rgba(255, 255, 255, 0.677);
  height: 35px;
  border-radius: 8px;
  width: 65%;
}
.wallet-transaction-select {
  width: 150px;
}

.wallet-transaction-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 35px !important;
}

.wallet-transaction-select:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.wallet_select:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}
.wallet-transaction-select .ant-select-selection-item,
.wallet_select .ant-select-selection-item {
  font-weight: 600;
}
.wallet-transaction-select .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
F.wallet_select .ant-select-selector .ant-select-selection-item {
  line-height: 37px !important;
}
.wallet_card {
  margin-right: 10px;
}
.wallet_red_card_inner {
  min-height: 130px;
}
.wallet_dropdown li {
  list-style-type: none;
}
.wallet_inner_heading {
  font-size: 0.875rem;
}
.wallet_card_amount {
  font-size: 1.125rem;
}
.wallet_search {
  height: 35px;
}
.preview_image {
  width: 371px;
  height: 371px;
}
/* ========================  
    2. P R E L O A D E R  
========================== */
#preloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
#preloader .progress {
  height: 5px !important;
  width: 400px !important;
}
#preloader.loaded {
  opacity: 1;
  z-index: 99999;
  animation-name: fadeout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
.w-sm-25 {
  flex-basis: 24%;
}
.wallet_card_body {
  flex: 1 1 auto;
  padding: 1rem 0.5rem;
}

/* A M I N A T I O N */
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 99999;
  }
  100% {
    opacity: 0;
    z-index: -9999;
  }
}
@media screen and (max-width: 576px) {
  /* mobile configuration for preloader */
  #preloader .progress {
    height: 5px !important;
    width: 280px !important;
  }
  .wallet_info_container {
    grid-column: span 1;
  }
  .wallet-transaction-select {
    width: 100%;
  }

  .wallet_select .ant-select-selection-item {
    font-size: 12px;
  }
  .wallet_red_card_inner {
    min-height: 108px;
  }
  .balance__amount {
    font-size: 1rem !important;
  }
  .w-sm-25 {
    width: 100%;
  }
  .new_wallet_btn {
    width: 100px;
    height: 110px;
  }
}

/* ==================================================  
    3. N A V B A R     C O N F I G U R A T I O N
================================================== */
.navbar {
  transition: 0.2s all ease-in-out;
}
.nav-item {
  padding: 0 7px;
}
.nav-link {
  font-weight: var(--font-normal);
  font-size: var(--font-small);
  color: var(--color-dark) !important;
}
.nav-link:hover,
.nav-link.active {
  color: var(--color-red) !important;
}

/* ==================================================  
    4. H E R O     S E C T I O N
================================================== */
.hero {
  position: relative;
  background: linear-gradient(to left bottom, #fa433259, #ffffff);
  min-height: 700px;
}
.hero .hero-content {
  position: relative;
  padding-top: 200px;
}
.hero .hero-content h1 {
  font-size: 72px;
  font-weight: 600 !important;
}
.hero .hero-content .badge {
  padding: 8px 10px;
}
.hero .hero-bg-content .hero-map {
  position: absolute;
  filter: invert(1);
  opacity: 0.1;
  width: 100vw;
  top: 0px;
  left: 0px;
}
.hero .hero-bg-content .hero-vector-1 {
  position: absolute;
  filter: brightness(0) invert(1);
  top: 0px;
  right: 0px;
  opacity: 0.5;
  height: 600px;
}
.hero .hero-bg-content .hero-vector-2 {
  position: absolute;
  filter: brightness(0) invert(1);
  top: 80px;
  right: 0px;
  opacity: 0.5;
  height: 550px;
}
.hero .hero-rectangle {
  position: absolute;
  filter: brightness(0) invert(1);
  height: 450px;
  width: 700px;
  top: -20px;
  right: -35px;
  opacity: 0.5;
}
.hero-card-details li {
  position: relative;
}
.hero-card-details li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--color-red);
  border-radius: 50%;
  left: 0px;
  top: 13px;
}
.hero-card-details li::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: var(--color-red);
  left: 4.5px;
  top: 15px;
}
.hero-card-details li:last-of-type:after {
  display: none;
}
.hero-content-card {
  position: absolute;
  width: 100%;
  top: 10px;
}
.h-80 {
  height: 80%;
}
.rounded-lg {
  border-radius: 1rem;
}
.new_wallet_btn {
  width: 75%;
  border-radius: 1rem;
  height: 80%;
  border: 1px solid var(--color-red);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 1200px) {
  .hero .hero-content {
    position: relative;
    /* padding-top: 150px; */
  }
  .wallet_info_container {
    grid-column: span 1;
  }

  .new_wallet_btn {
    width: 100px;
    height: 110px;
  }
}
@media screen and (max-width: 991px) {
  .hero-content-card {
    position: relative;
  }
  .hero .hero-content {
    padding-top: 80px;
  }
  .hero .hero-content h1 {
    font-size: 32px;
    font-weight: 600 !important;
  }
}
/* ==================================================  
     BLOG POSTS   
================================================== */
.blog-section-header {
  text-align: center;
}
.blog-list {
  display: flex !important;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 16px;
}
.carousel-width {
  width: 250px;
  display: none !important;
}
/* ==================================================  
    5. C O U N T R I E S      S E C T I O N 
================================================== */
.countries {
  background: url("../images/components/Frame\ 52.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ==================================================  
    6. F O O T E R   
================================================== */
.footer-img {
  filter: brightness(0) invert(1);
  opacity: 0.8;
}

/* ==================================================  
    7. M A S T H E A D 
================================================== */
.masthead {
  position: relative;
  background: linear-gradient(to top, #fa433259, #ffffff);
  min-height: 400px;
  /* overflow-x: hidden; */
}
.masthead .masthead-content {
  position: relative;
  padding-top: 150px;
  padding-bottom: 10px;
}
.masthead-bg-content {
  position: relative;
  overflow-x: hidden;
}
.masthead-bg-content .masthead-bg {
  position: absolute;
  filter: brightness(0) invert(1);
  opacity: 0.3;
}
.masthead-bg-content .masthead-bg-1 {
  bottom: 0px;
  left: 200px;
}
.masthead-bg-content .masthead-bg-2 {
  bottom: 0px;
  right: -120px;
}
.masthead-bg-content .masthead-bg-3 {
  bottom: 0px;
  right: 0px;
}

/* ==================================================  
    8. C U S T O M    N A V
================================================== */

.custom-nav .nav-item {
  margin-right: 10px;
}
.custom-nav .nav-item .nav-link {
  padding: 0px;
  padding: 5px;
  border-radius: 0%;
  text-transform: uppercase;
  font-weight: 400;
}
.custom-nav .nav-item .nav-link.active {
  background: transparent !important;
  border-bottom: 1px solid var(--bs-dark);
  font-weight: 600;
}

/* ==================================================  
    9. H E L P     C A R D
================================================== */
.help-card {
  position: relative;
  transition: 0.2s all ease-in-out;
  height: 210px;
}
.help-card:hover {
  border-color: var(--color-red);
  color: var(--color-red);
  box-shadow: 0px 3px 10px 8px var(--color-red-light);
}
/* .help-icon-red {
    display: none;
}
.help-card:hover .help-icon{
    display: none;
}
.help-card:hover .help-icon-red{
    display: inline-block;
} */

/* ==================================================  
    10. L O G I N    D E S I G N S
================================================== */
.login-section {
  position: relative;
  padding: 150px 0;
}
.login-section .row {
  z-index: 1;
}
.login-design {
  position: fixed;
  opacity: 0.8;
  width: 300px;
}
.login-design-1 {
  top: 0px;
  right: 0px;
}
.login-design-2 {
  bottom: 0px;
  left: 0px;
}
@media screen and (max-width: 991px) {
  .login-design {
    width: 200px;
  }
}

/* ==================================================  
    11. S I D E    C A R D
================================================== */
.side-card {
  position: relative;
  width: 400px;
  height: 100vh;
}
.side-card .text-center {
  position: relative;
  z-index: 4;
}
.side-card-bg {
  position: absolute;
}
.side-card-bg-1 {
  bottom: 0px;
  left: 0px;
  width: 250px;
  z-index: 2;
  opacity: 0.9;
}
.side-card-bg-2 {
  bottom: 0px;
  left: 50px;
  width: 230px;
  filter: brightness(0) invert(1);
  opacity: 0.7;
  z-index: 1;
}

/* ==================================================  
    11. S I D E    C A R D
================================================== */
.auth-mainbody {
  position: relative;
  padding-left: 400px;
}

@media screen and (max-width: 1200px) {
  .auth-mainbody {
    padding-left: 0px;
  }
  .w-sm-25 {
    width: 100%;
  }
}

/* ==================================================  
    12. A U T H     N A V
================================================== */
.auth-nav {
  justify-content: center;
  margin-bottom: 20px;
}
.auth-nav .nav-box {
  display: inline-flex;
  height: 45px;
  width: 45px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-gray-light);
  margin-right: 10px;
}
.auth-nav .nav-text {
  color: var(--color-gray-dark);
}
.auth-nav .nav-link.active .nav-text,
.auth-nav .nav-link.done .nav-text {
  color: var(--color-dark);
}
.auth-nav .nav-link.active .nav-box {
  background-color: var(--color-red);
}
.auth-nav .nav-link.done .nav-box {
  background-color: var(--color-pink);
  color: var(--color-dark);
}

/* =====================================================  
    18. N A M E     C A R D
======================================================== */
.name-card {
  height: 40px;
  width: 40px;
  background-color: var(--color-red-light);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* =================================  
    19. D D - S L I C K
==================================== */
.dropdown-select .dropdown-menu {
  max-height: 410px;
  overflow-y: auto;
}
.dropdown-select .input-group {
  border-radius: var(--rounded) !important;
  border-width: 0px;
}
.dropdown-select .dropdown-menu .input-group-text {
  border-radius: var(--rounded) 0 0 var(--rounded) !important;
}
.dropdown-select .dropdown-menu .form-control {
  background-color: #e9ecef;
  border-radius: 0 var(--rounded) var(--rounded) 0 !important;
}

.dropdown-select .dropdown-item:active,
.dropdown-select .dropdown-item:hover {
  background-color: var(--color-red-light);
  color: var(--color-red);
}

.dropdown-select .dropdown-toggle::after {
  margin-top: 10px;
  float: right;
}
/* .option-label {
    margin-right: auto; /* Pushes the span element to the right */
/* } */

.option-count {
  margin-left: auto; /* Pushes the div element to the left */
}

/* =============================================  
    20. B E N E F I C I A R Y     I N F O
================================================= */
.beneficiary-info {
  display: none;
}
.beneficiary-info.active {
  display: block;
}

/* =======================================================  
    21. C U S T O M I Z E D     F O R M    C H E C K S
========================================================== */
.form-check .form-check-input:checked {
  background-color: var(--color-red);
  border-color: var(--color-red);
}
.form-check .form-check-input[type="radio"] {
  float: right;
  margin-left: 0;
}
.form-check .form-check-label.adjust {
  margin-left: -20px;
}

/* =============================================  
    22. P A G I N A T I O N
================================================= */
.pagination {
  border: none;
}
.pagination .page-item {
  padding: 0px 4px;
}
.pagination .page-item.disabled .page-link {
  background-color: var(--alert-secondary);
}
.pagination .page-item.active .page-link {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);
}
.pagination .page-link {
  border-radius: 8px !important;
  color: var(--color-dark);
}

/* ====================================================  
    23. P R O F I L E     C O N F I G U R A T I O N
======================================================= */
.profile-image {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--color-brown);
  display: flex;
  overflow: hidden;
}
.profile-image .profile-name-tag {
  display: block;
  color: var(--color-white);
  margin: auto;
  font-size: 22px;
  font-weight: var(--font-bold);
}
.profile-img-tag {
  position: absolute;
  top: 0px;
  left: 0px;
}

/* ====================================================  
    24. W H Y     C H O O S E     U S ?
======================================================= */
.why-choose {
  position: relative;
  background: var(--color-white);
  transition: 0.2s all ease-in-out;
  overflow: hidden;
}
.why-choose .bg-icon,
.why-choose .card-icon-red {
  display: none;
}
.why-choose:hover {
  background: linear-gradient(to top, #fa433259, #ffffff);
  box-shadow: 0px 10px 10px 5px var(--color-gray-light);
}
.why-choose:hover .header-2 {
  color: var(--color-red);
}
.why-choose:hover .bg-icon {
  top: -95px;
  right: 30px;
  display: block;
}
.why-choose:hover .card-icon-red {
  display: block;
}
.why-choose:hover .card-icon {
  display: none;
}
a.link {
  font-size: 18px;
}

/* ==================================================  
    25. P R I V A C Y   P O L I C Y  
================================================== */
.privacy-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 40px;
}
.privacy-image {
  width: 250px;
  margin-bottom: 10px;
}
.privacy-container section {
  text-align: justify;
}
.privacy-container section a {
  text-decoration: none;
}
.privacy-container section h5 {
  font-weight: 600;
}
.mobile_referral_wrapper{
  display: none;
}
.referral_wrapper {
  margin-top: 52px;
}
.referral_container {
  background-color: var(--color-red-light);
  padding: 10px;
  border-radius: 8px;
}
.referral_container a {
  font-size: 14px;
  color: var(--color-red) !important;
  word-break: break-all;
}
.referral-exclamation-container{
  border-bottom: 1px solid #c7c5cb;
  padding-bottom: 5px;
}
 .referral-exclamation {
  background-color: var(--color-red-light);
  border-radius: 8px;
  padding: 7px 10px;
}
.referral-exclamation-text{
  font-size: 12px;
  margin-left: 45px;
}
.referral-modal-footer{
  border-top: 1px solid #c7c5cb;
  padding-top: 20px;
}

/* ==================================================  
    26. TERMS AND CONDITIONS  
================================================== */
.terms-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 40px;
}
.terms-container section {
  text-align: justify;
}
.terms-container section li::marker {
  font-weight: bold;
}
.w-full {
  width: 100%;
}
.justify {
  text-align: justify;
}
.terms-modal-w {
  width: 75% !important;
}

@media screen and (max-width: 991px) {
  .why-choose {
    border: 1px solid var(--color-light) !important;
  }
}

/* DROPDOWN SEARCH */
.dropdown-search {
  display: block;
  padding: 0px 15px;
  margin-bottom: 25px;
}
.dropdown-search .input-group .form-control {
  background-color: #e9ecef;
}

.search-input {
  position: relative;
}
.search-input .search-suggestions {
  position: absolute;
  display: none;
  top: 52px;
  width: 100%;
  max-height: 190px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 0 0 12px 12px;
  overflow-y: auto;
  transition: 0.2 all ease-in-out;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}
.search-input.active .input-group .input-group-prepend .input-group-text {
  border-radius: 14px 0 0 0 !important;
}
.search-input.active .input-group .input-group-append .input-group-text {
  border-radius: 0 14px 0 0 !important;
}
.search-input.active .search-suggestions {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.search-input .search-suggestions .suggestion-item {
  position: relative;
  display: block;
  margin: 0 10px;
  border-radius: 12px;
  padding: 10px 20px;
  transition: 0.2 all ease-in-out;
  background-color: #fff;
}
.search-input .search-suggestions .suggestion-item:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

footer {
  background: var(--color-dark-red);
  color: var(--color-white);
}
.footer-brand span {
  display: block;
  margin-bottom: 0px;
  font-size: 24px;
}

.send-money-btn {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 144px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px 0 24px 0;
  background: linear-gradient(89.67deg, #140201 -302.46%, #e91908 95.85%);
  color: var(--color-white);
  height: 52px;
  white-space: nowrap;
  transition: 0.2s all ease-in-out;
}
.send-money-img {
  margin-bottom: 7px;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.business-pending {
  color: var(--color-warning);
}
.business-processing {
  color: #007bff;
}
.business-failed {
  color: var(--color-red);
}
.business-verified {
  color: var(--color-success);
}
.send-money-btn:hover .send-money-img {
  margin-right: 5px;
}
.send-money-title {
  font-size: 24px;
}
.send-money-tab {
  display: none;
}
.send-money-tab.active {
  display: block;
}
.text-sm {
  font-size: 12px;
}
/* upload level */
.upload-level {
  background-repeat: no-repeat;
  background-size: cover;
}
.upload-level .card-title {
  font-size: 20px;
}
.upload-level-one {
  background-image: url("../images/components/trianglify-lowres 1.png");
}
.upload-level-two {
  background-image: url("../images/components/trianglify-lowres1.png");
}
.upload-level-three {
  background-image: url("../images/components/trianglify-lowres2.png");
}

.country-dropdown {
  max-height: 500px;
  width: 350px;
  overflow-y: auto;
}
.country-dropdown-small {
  width: 238px !important;
  right: -15px !important;
}
.bold-label {
  white-space: nowrap;
}

.accordion_image_icon {
  height: 34px;
  border-radius: 50%;
  background: #fef2f0;
  padding: 5px;
}

.multi-selector:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2);
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 45px;
}
.facial_warning {
  color: white;
}
.verify_text {
  cursor: pointer;
  color: white;

  text-decoration: underline !important;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .terms-container {
    padding: 40px 15px;
  }
  .terms-modal-container {
    padding: 20px 5px !important;
  }
  .country-dropdown {
    height: 95vh;
    width: 95vw;
  }

  .privacy-containter h1 {
    font-size: 24px;
    font-weight: 700;
  }
  .privacy-containter h3 {
    font-size: 18px;
  }
  .privacy-image {
    width: 200px;
    margin-bottom: 10px;
  }
  .bold-label {
    white-space: wrap;
  }
  .terms-modal-w {
    width: 100% !important;
  }
}

@media screen and (max-width: 350px) {
  .country-dropdown {
    height: 95vh;
    width: 95vw;
  }
}

/* ==================================================  
    27. TRANSACTION RECEIPT 
================================================== */
.section_wrapper {
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  background: white;
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto auto;
}

.cos_mail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
  margin-bottom: 3rem;
}

.cos_mail p {
  font-weight: 600;
  font-size: 13px;
}

.logo_address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto auto 1rem auto;
}

.logo___receipt___ {
  width: 130px;
  margin-bottom: 2rem;
}

.logo___receipt___ .receipt__logo_img {
  width: 100%;
  object-fit: cover;
}

.logo_address p {
  font-weight: 600;
  font-size: 13px;
}

.transaction_info {
  background: #fffafa;
  padding: 1rem 1rem;
}

.transaction_details_modal_receipt .transaction_info h1 {
  font-size: 25px;
  font-weight: 600;
}

.check_div {
  width: 23px;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  border: 1px solid #008000;
  background: #008000;
  border-radius: 50%;
  margin-right: 7px;
  position: relative;
}

.check {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
}

.success_text {
  color: #008000;
}

.amount_aud {
  display: flex;
  justify-content: space-between;
  margin: auto 1rem;
}

.amount_aud p {
  font-weight: 600;
}

.table_transaction_user_info {
  background: #fff;
  margin-top: 1rem;
  padding: 0 1rem;
}

.table_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.table_info h2 {
  font-size: 15px;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.table_info table {
  width: 100%;
  border-collapse: collapse;
}
.comment-Warning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}
.table_info tr {
  display: flex;
}

.table_info tr td {
  border: 0.1rem solid #ccc;
  width: 50%;
  display: flex;
  place-items: center;
  justify-content: center;
}

.transaction_result {
  width: 100%;
  display: flex;
  text-wrap: wrap;
  text-align: center;
  margin: auto;
  padding: 10px;
}

.transaction_result p {
  width: 100%;
  overflow-wrap: break-word;
}

.note {
  background: #fffafa;
  margin-top: 1rem;
  padding: 0 1rem;
}

.note h2 {
  font-size: 15px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.note p {
  max-width: fit-content;
  font-size: 12px;
  padding: 0 0 0.5rem 0;
}

.btn_share {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}

.btn_share button {
  display: block;
  background: red;
  color: white;
  border: none;
  padding: 10px 1rem;
  border-radius: 10px;
}

.footer_receipt_transaction {
  width: 100%;
  margin-top: 4rem;
}

.footer_receipt_transaction small {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 500;
}

/* =========================================== BUSINESS ACCOUNT ============================================= */

/* BUSINESS ACCOUNT */
.section_wrap_business_create_acount {
  width: 100%;
  max-width: 800px;
  padding: 1rem 0px;
  background: white;
  /* box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto auto;
}

.create_acount_title {
  display: flex;
  border-bottom: 2px solid #ccc;
  padding: 1rem 2rem;
}

.sub_section_info {
  max-width: 800px;
  padding: 2rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notification__message-temporary {
  display: flex;
  border: 2px solid var(--color-red);
  border-radius: 10px;
  align-items: center;
  padding: 0.6rem 1.5rem;
  gap: 3rem;
}

.icon__message_paragraph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.notification__message-temporary p {
  max-width: 600px;
}
.icon_message-wrap {
  width: 10%;
}

.icon_message-wrap .icon_message {
  font-size: 40px;
  color: red;
}

.close_err-message-wrap {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.close_err-message-wrap .close_err-message {
  font-size: 25px;
  color: red;
}

/* FORM */

.sub__section_data_info {
  padding: 2rem;
}

.inputs_business_info {
  margin-top: 1rem;
}

.inputs_business_info label {
  color: #333;
}

.inputs_business_info input {
  position: relative;
  width: 100%;
  /* max-width: 690px; */
  height: 40px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #ccc;
  /* font-size: 0.7rem; */
  color: #707070;
  padding: 1.3rem 17px;
  margin-top: 10px;
}

.business_account_column_inputs {
  display: flex;
  column-gap: 15px;
  width: 100%;
}

.inputs_business_info_select {
  width: 100%;
}

.ant-select-outlined {
  width: 100%;
  position: relative;
  height: 40px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.ant-select-selection-search {
  outline: none;
  height: 40px;
}

.anticon {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .mobile_referral_wrapper{
    display: block;
  }
  .close_err-message-wrap .close_err-message {
    display: none;
    position: absolute;
    top: 350px;
    right: 60px;
    z-index: 999;
  }

  .notification__message-temporary:hover .close_err-message {
    display: flex;
  }

  .notification__message-temporary:hover .icon_message {
    display: none !important;
  }

  .icon__message_paragraph p {
    font-size: 15px;
    width: 100%;
    text-align: center;
  }
  .blog-list {
    display: none !important;
  }
  .mobile-blog-list {
    display: flex;
    justify-content: center;
  }
  .carousel-width {
    width: 250px;
    display: block !important;
  }
  .icon__message_paragraph {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .notification__message-temporary {
    display: block !important;
    width: 100%;
  }
  .notification__message-temporary p {
    width: 100% !important;
    max-width: 500px;
  }
}

@media screen and (max-width: 470px) {
  .close_err-message-wrap .close_err-message {
    display: none;
    position: absolute;
    top: 335px;
    right: 50px;
    z-index: 999;
  }

  .notification__message-temporary:hover .icon__message_paragraph {
    display: block !important;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
}

/* FILE UPLOAD */

.business_account_upload_file {
  margin-top: 2rem;
}

.business_upload_file_wrapper {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-radius: 6px;
  min-height: 150px;
  background-image: linear-gradient(180deg, #edf4fb, #f1f6ff);
  position: relative;
  cursor: pointer;
  margin-top: 0.5rem;
}

.business_files_drag_drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2;
  position: relative;
}

.cloud_icon {
  font-size: 50px;
}

.button_span .file_selector {
  border: none;
  background: transparent;
  color: blue;
  font-size: 18px;
}

.button_span span {
  font-size: 15px;
}

.business_files_drag_drop input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

/* buttons */

.Business_create_account_back {
  display: flex;
  column-gap: 1rem;
  border-top: 1px solid #ccc;
  padding-right: 2rem;
  padding-top: 2rem;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 2rem;
}

.back_button {
  background: #f1f1f2;
  color: #868080;
  border-radius: 8px;
  border: 0;
}

.create_account {
  background: red;
  color: white;
}

.back_button:hover,
.create_account:hover {
  opacity: 0.5;
}

/*  */
/* DASHBOARD NEWLY FEATURE */
.new_to_business_account_wrapper {
  /* width: 100%; */
  max-width: 1200px;
  background-color: #f0fff0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 1px solid #6ab56a;
}

.newly_dashboard_icon_info {
  display: flex;
  align-items: center;
  max-width: 600px;
  gap: 18px;
}

.header_paragraph_newly h2 {
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.header_paragraph_newly p {
  margin-top: 0px;
}

.newly_icon .newly__icon {
  font-size: 24px;
  color: #008000;
}

.create_account_user_dismiss {
  padding: 0.5rem;
  background-color: #f1f1f2;
  color: black;
  font-size: 15px;
  border: none;
  border-radius: 8px;
}
.notice_btn {
  min-height: 25px;
}
.create_account_user_dismiss:hover {
  background-color: #d6d6d7;
}

.bus_info_new {
  line-height: 1;
}
.bus_info_new h2 {
  font-size: 25px;
  font-weight: bold;
}

.business_account_numbers_of_files_uploaded {
  width: 40%;
  height: 10% !important;
  padding-top: 1rem !important;
}

.uploaded_image_preview img {
  width: 100%;
  height: 10%;
  border-radius: 6px;
  overflow: hidden;
}

.custom___gap {
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .new_to_business_account_wrapper {
    padding: 0.5rem 1rem 1rem 1rem;
    flex-direction: column;
  }

  .newly_icon .newly__icon {
    display: none;
  }

  .bus_info_new h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.1rem;
    line-height: normal;
  }
  .bus_info_new {
    line-height: 1;
    margin-bottom: 0.2rem;
    text-align: center;
  }

  .create_accoun_ne_user {
    padding: 0.5rem 1rem;
    background-color: red;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 6px;
  }
}

/* ======================================================================= */
.bank_payId_wrap {
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem;
}

.payId_button {
  display: flex;
  justify-content: space-between;
}

.payId_icon_wrap {
  width: 30%;
  /* max-width: 1500px; */
}

.payId_icon_wrap .payId_Icon {
  width: 100%;
  /* max-width: 1500px; */
}

.bank_button_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bank_button,
.payId_button {
  width: 50%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  border: 1px solid red;
  border-radius: 6px;
  padding: 1rem 20px;
}

.bank_icon {
  font-size: 25px;
}

.warning_icon {
  font-size: 20px;
}
.offcanvas {
  /* z-index: 1045; */
  /* z-index: initial; */
}

.offcanvas-backdrop {
  /* z-index: 1040; */
  /* z-index: initial; */
}

/* ======================================================================= */
@media (max-width: 768px) {
  .outbound_transaction-settings {
    display: block !important;
  }

  .business_account_column_inputs {
    flex-wrap: wrap;
  }

  .Business_create_account_back {
    flex-direction: column-reverse;
    padding: 10px 10px;
    align-items: center;
    gap: 10px;
  }
  .back_button {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .offcanvas {
    /* z-index: 1045; */
    z-index: 104;
  }

  .offcanvas-backdrop {
    /* z-index: 1040; */
    z-index: 100;
  }
}

@media (max-width: 1200px) {
  .outbound_transaction-settings {
    display: block !important;
  }
}

/* ======================================================================= */

@media screen and (min-width: 470px) {
  /* .ant-modal */
  .ant-modal-wrap {
    /* z-index: 100 !important; */
  }

  .ant-modal-mask {
    /* z-index: 100 !important; */
  }
}

/* ======================================================================= */
/* MODAL COMPONENT STYLING  */
/* ======================================================================= */

/* src/Modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 800px;
  position: relative;
  border: none;
  /* margin-top: 10%; */
  max-height: 95vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.closeModal_icon {
  font-size: 20px;
}

@media screen and (max-width: 786px) {
  .modal-backdrop {
    z-index: 100;
  }
}
/* ============================SELECT COMPONENT =============================== */

.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  height: 2.8rem;
  margin-top: 0.7rem;
  width: 100%;
}

.selected-value {
  flex: 1;
  text-indent: 10px;
  font-size: 13px;
  font-weight: 500;
  z-index: 999;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.selected-option {
  background-color: transparent;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
}

.select-arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.option {
  padding: 10px;
  cursor: pointer;
  z-index: 9999;
}

.option.selected {
  background-color: #f6bfbb;
  color: black;
}

.option:hover {
  background-color: #f5f5f5;
}

.search-input {
  flex: 1;
  border: 0px solid white !important;
  outline: none;
  font-size: 13px;
  padding: 5px 10px;
}
