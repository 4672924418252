.create_modal_title {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 13px;
	border-radius: 8px;
	background: #edf4fb;

}
/* width: 100%; */

.create_modal_title small {
	color: black;
}

.Create__span {
	font-weight: 700;
}

/* SELECT */

.OPTION__SELECTION {
	margin-top: 1rem;
	display: flex;
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px 10px 25px;
	position: relative;
}

.OPTION__SELECTION .select_icon__ {
	display: flex;
	flex-direction: column;
	width: 95%;
}

.select_icon__ > label {
	padding-left: 5px;
	font-size: 9px;
	pointer-events: none;
}

.arrow_icon {
	display: flex;
	height: auto;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 100;
	width: auto;
	position: absolute;
	right: 15px;
	top: 17px;
}

.OPTION__SELECTION #select_opt {
	border: 0px;
	outline: 0px;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	margin-top: 3px;
	padding-left: 3px;
	cursor: pointer;
	position: absolute;
	top: 0px;
	right: 0px;
	padding-left: 15px;
	padding-top: 20px;
	background: transparent;
}

.OPTION__SELECTION #select_opt > option {
	font-size: 12px;
}

/* BUTTON */

.BUTTON_btn_wallet {
	width: 100%;
	margin-top: 3.5rem;
}

.btn__submit_wallet {
	width: 95%;
	border-radius: 8px;
	border: 0px;
	background: red;
	color: white;
	padding: 1rem 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

/* T-A-B-L-E-T */
@media screen and (max-width: 1024px) {
}
