/*
    ==========================================================================
    All right reserved.
    ==========================================================================




    ==========================================================================
    TABLE OF CONTENT
    ==========================================================================

    1. Predefined variables
    2. Preloader
    3. Navbar Configuration
    4. Hero Section
    5. Countries Section
    6. Footer
    7. Masthead
    8. Custom Nav
    9. Help Card
    10. Login Designs
    11. Side Card
    12. Auth Nav
    13. Dashboard Sidebar Design
    13. Dashboard Sidebar Design
    14. Mainbody
    15. Wrapper Config
    16. Dashboard Navbar
    17. Beneficiary
    18. Name card
    19. DD Slider
    20. Beneficiary Info
    21. Customized Form Checks
    22. Pagination
    23. Profile Configuration
    24. Why Choose Us?
    25. other



    ==========================================================================
    This section contains pre-defined variables and classes that would
    be used all through this project
    ==========================================================================
*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Josefin+Sans:wght@200;300;400;600;700&family=Lora:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&family=Yuji+Boku&display=swap");

:root {
  --color-red: #e91908;
  --color-red-light: #fef2f0;
  --color-red-light-2: #e9190842;
  --color-white: #fff;
  --color-dark: #140201;
  --color-gray-light: #d3d3d375;
  --color-gray-dark: var(--bs-secondary);
  --color-red-darken: #c71404;
  --color-dark-red: #140201;
  --color-white-darken: #f6f0f0;
  --color-pink: #facdc9;
  --color-light: #eeeeee;
  --color-info: #17a2b8;
  --color-brown: #4d3f3f;
  --color-brown-light: #cd7f32;
  --color-success: #008000;
  --alert-success: #00800021;
  --color-secondary: #82868b;
  --alert-secondary: #82868b21;
  --color-warning: #ffa500;
  --alert-warning: #ffa6001d;
  --rounded: 12px;
  --rounded-end-null: 12px 0 0 12px;
  --pill: 27px;
  --font-small: 14px;
  --font-normal: 600;
  --font-bold: 700;
  --dashboard-bg: #f5f5f5;
}

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "DM Sans", sans-serif;
  text-decoration: none !important;
  /* user-select: auto; */
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray-light) var(--color-light);
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--color-light);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-light);
  border-radius: 20px;
  border: 3px solid var(--color-gray-light);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-white);
}

.btn,
.form-control,
.form-select,
.navbar-toggler,
.btn-close,
.accordion-button {
  box-shadow: none !important;
  outline: none !important;
  font-size: var(--font-small);
  font-weight: var(--font-normal);
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  color: var(--color-gray-dark);
}

.form-control {
  user-select: auto;
}

.form-control::placeholder {
  font-weight: 400;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--color-gray-light);
}

.accordion-button {
  color: var(--color-dark);
}

.accordion-button:disabled {
  background-color: var(--color-white);
  opacity: 0.4;
}

.accordion-button:not(.collapsed) {
  color: var(--color-dark);
  background-color: var(--color-white);
}

.settings .accordion-button::after {
  filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%);
}

.red-btn-disabled {
  background-color: rgb(230, 84, 71);
}
.min-height {
  min-height: 45px;
}

a.min-height {
  display: inline-flex;
  align-items: center;
  min-height: 45px;
}

.rounded {
  border-radius: var(--rounded) !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.input-group .input-group-text,
.dropdown-select .form-control {
  border-radius: 8px 0 0 8px;
}

.input-group .input-group-text.end,
.dropdown-select .form-control.end {
  border-radius: 0 8px 8px 0;
}

.input-group.floating-group {
  height: 50px !important;
}

.input-group .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-file-upload .file-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  border: 1px solid var(--color-gray-light);
  background: #edf4fb;
  border-radius: 8px;
}

input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.rounded-end-0 {
  border-radius: 12px 0 0 12px !important;
}

.pill {
  border-radius: var(--pill) !important;
}

td,
th {
  white-space: nowrap;
  vertical-align: middle;
}

.wrap {
  min-width: 300px;
  white-space: normal !important;
}

.bg-red {
  background: var(--color-red);
}

.text-red,
.text-red:hover {
  color: var(--color-red);
}

.text-light-red,
.text-light-red:hover {
  color: var(--color-red-light-2) !important;
}

.text-brown {
  color: var(--color-brown-light);
}

.text-dark {
  color: var(--color-dark) !important;
}

.btn-white {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border-color: var(--color-gray-light) !important;
}

.btn-white:hover {
  background-color: var(--color-white-darken) !important;
  border-color: var(--color-white-darken) !important;
}

.status-width {
  display: inline-block;
  width: 130px;
  text-align: center;
}

.btn-red {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}
.util-div {
  padding: 5px;
}
.btn-yellow {
  background-color: rgb(255, 208, 0) !important;
  color: var(--color-white) !important;
  border-color: rgb(255, 208, 0) !important;
}
.btn-yellow:hover {
  background-color: rgb(184, 151, 6) !important;
  color: var(--color-white) !important;
  border-color: rgb(184, 151, 6) !important;
}
.btn-outline-red {
  border-color: var(--color-red) !important;
  color: var(--color-red) !important;
}

.btn-outline-red:hover {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-color: var(--color-red) !important;
}

.btn-red:hover {
  background-color: var(--color-red-darken) !important;
  border-color: var(--color-red-darken) !important;
}

.alert-danger {
  color: var(--color-red);
  background-color: #facdc9 !important;
}

.alert-secondary {
  color: var(--color-secondary);
  border-color: var(--alert-secondary);
  background-color: var(--alert-secondary);
}

.alert-success {
  color: var(--color-success);
  border-color: var(--alert-success);
  background-color: var(--alert-success);
}

.alert-warning {
  color: var(--color-warning);
  border-color: var(--alert-warning);
  background-color: var(--alert-warning);
}

.section {
  padding: 80px 0;
}

.header {
  font-size: 61px;
}

.header-2 {
  font-size: 54px;
}

.header-3 {
  font-size: 48px;
}

.header-4 {
  font-size: 36px;
}

.fw-bold {
  font-weight: var(--font-normal) !important;
}

.bg-linear-pink {
  background: linear-gradient(to top, #fa433259, #ffffff);
}

.bg-linear-red {
  background: linear-gradient(to right, #000, #530d06);
}

.bg-pink {
  background-color: var(--color-pink);
}

p,
a,
label,
.alert {
  font-size: var(--font-small);
}

.bg-light {
  background-color: var(--color-light) !important;
}

.bg-red-light {
  background-color: var(--color-red-light) !important;
}

.text-info {
  color: var(--color-info) !important;
}

.text-success {
  color: var(--color-success) !important;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link.active {
  color: var(--color-dark) !important;
}

/* ========================  
    2. P R E L O A D E R  
========================== */
#preloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

#preloader .progress {
  height: 5px !important;
  width: 400px !important;
}

#preloader.loaded {
  opacity: 1;
  z-index: 99999;
  animation-name: fadeout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

/* A M I N A T I O N */
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 99999;
  }

  100% {
    opacity: 0;
    z-index: -9999;
  }
}

@media screen and (max-width: 576px) {
  /* mobile configuration for preloader */
  #preloader .progress {
    height: 5px !important;
    width: 280px !important;
  }
}

/* ==================================================  
    3. N A V B A R     C O N F I G U R A T I O N
================================================== */
.navbar {
  transition: 0.2s all ease-in-out;
}

.nav-item {
  padding: 0 7px;
}

.nav-link {
  font-weight: var(--font-normal);
  font-size: var(--font-small);
  color: var(--color-dark) !important;
}

.nav-link:hover,
.nav-link.active {
  color: var(--color-red) !important;
}

/* ==================================================  
    8. C U S T O M    N A V
================================================== */

.custom-nav .nav-item {
  margin-right: 10px;
}

.custom-nav .nav-item .nav-link {
  padding: 0px;
  padding: 5px;
  border-radius: 0%;
  text-transform: uppercase;
  font-weight: 400;
}

.custom-nav .nav-item .nav-link.active {
  background: transparent !important;
  border-bottom: 1px solid var(--bs-dark);
  font-weight: 600;
}

/* ===========================================================  
    13. D A S H B O A R D    S I D E B A R    D E S I G N
============================================================== */
.admin-sidebar {
  position: fixed;
  height: 100vh;
  width: 250px;
  background-color: var(--dashboard-bg);
  top: 0px;
  left: 0px;
  padding: 10px;
  z-index: 1000;
  transition: 0.2s all ease-in-out;
  overflow-y: auto;
  border-radius: 20px;
}

.admin-sidebar .sidebar-header {
  position: relative;
  margin-bottom: 40px;
}

.admin-sidebar .sidebar-logo-half {
  display: none;
}

.admin-sidebar .sidebar-icon-open {
  display: none;
}

.admin-sidebar .sidebar-toggle-btn {
  position: absolute;
  height: 27px;
  width: 27px;
  top: 7px;
  right: -33px;
  background: var(--color-red-light);
  color: var(--color-red);
  border: 1px solid var(--color-red-light);
  border-radius: 50%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-sidebar .sidebar-nav .sidebar-link {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  color: var(--color-gray-dark);
  font-size: var(--font-small);
  white-space: nowrap;
  transition: 0.2s all ease-in-out;
}

.admin-sidebar .sidebar-nav .sidebar-text {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.admin-sidebar .sidebar-dropdown .sidebar-text {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.admin-sidebar .sidebar-nav .sidebar-link .sidebar-arrow {
  transform: rotate(0deg);
  transition: 0.2s all ease-in-out;
}

.admin-sidebar .sidebar-nav .sidebar-link[aria-expanded="true"] .sidebar-arrow {
  transform: rotate(180deg);
}

.admin-sidebar .sidebar-nav .sidebar-link.active {
  background-color: var(--color-white);
  color: var(--color-red);
  border-radius: 12px;
  font-weight: var(--font-normal);
}

.admin-sidebar .sidebar-nav .sidebar-link:hover {
  background-color: var(--color-white-darken);
  color: var(--color-dark-red);
  border-radius: 12px;
  font-weight: var(--font-normal);
}

.admin-sidebar .sidebar-nav .sidebar-link .sidebar-icon {
  display: inline-flex;
  width: 50px;
  justify-content: center;
  text-align: center;
}

.admin-sidebar .sidebar-dropdown .sidebar-link {
  padding: 15px;
  border-bottom: 1px solid var(--color-gray-light);
  border-radius: 0% !important;
}

.admin-sidebar .sidebar-dropdown .sidebar-item:last-child .sidebar-link {
  border-bottom: none;
  position: relative;
}

.admin-sidebar .sidebar-dropdown .sidebar-link:hover,
.admin-sidebar .sidebar-dropdown .sidebar-link.active {
  background-color: transparent;
  color: var(--color-dark-red);
  font-weight: var(--font-normal);
}

@media screen and (max-width: 991px) {
  .admin-sidebar {
    left: -251px;
  }

  .admin-sidebar .sidebar-toggle-btn {
    right: -65px;
  }

  .admin-sidebar .sidebar-icon-open {
    display: block;
  }

  .admin-sidebar .sidebar-icon-close {
    display: none;
  }
}

/* ===========================================================  
    14. M A I N B O D Y
============================================================== */
.mainbody {
  position: absolute;
  padding-left: 250px;
  top: 0px;
  left: 0px;
  background-color: var(--color-white);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  transition: 0.2s all ease-in-out;
}

@media screen and (max-width: 991px) {
  .mainbody {
    padding: 0px;
  }
}

/* ===========================================================  
    15.  W R A P P E R     C O N F I G
============================================================== */
#wrapper.open .admin-sidebar {
  width: 70px;
}

#wrapper.open .admin-sidebar .sidebar-link .sidebar-text {
  display: none;
}

#wrapper.open .admin-sidebar .sidebar-link .sidebar-icon {
  width: 100%;
}

#wrapper.open .admin-sidebar .sidebar-logo-full {
  display: none;
}

#wrapper.open .admin-sidebar .sidebar-logo-half {
  display: block;
}

#wrapper.open .admin-sidebar .sidebar-icon-open {
  display: block;
}

#wrapper.open .admin-sidebar .sidebar-icon-close {
  display: none;
}

#wrapper.open .admin-sidebar .sidebar-nav .sidebar-item:last-of-type {
  left: 25px;
}

#wrapper.open .mainbody {
  padding-left: 70px;
}

@media screen and (max-width: 991px) {
  #wrapper.open .admin-sidebar {
    left: 0px;
    width: 250px;
  }

  #wrapper.open .admin-sidebar .sidebar-toggle-btn {
    right: -33px;
  }

  #wrapper.open .admin-sidebar .sidebar-link .sidebar-text {
    display: inline-block;
  }

  #wrapper.open .admin-sidebar .sidebar-link .sidebar-icon {
    width: 50px;
  }

  #wrapper.open .admin-sidebar .sidebar-logo-full {
    display: block;
  }

  #wrapper.open .admin-sidebar .sidebar-logo-half {
    display: none;
  }

  #wrapper.open .admin-sidebar .sidebar-icon-open {
    display: none;
  }

  #wrapper.open .admin-sidebar .sidebar-icon-close {
    display: block;
  }

  #wrapper.open .admin-sidebar .sidebar-nav .sidebar-item:last-of-type {
    left: 0px;
  }

  #wrapper.open .mainbody {
    padding-left: 0px;
  }
}

/* ===========================================================  
    16. D A S H B O A R D     N A V B A R
============================================================== */
.mainbody .navbar .nav-link.btn {
  width: 40px;
}

.mainbody .navbar .nav-item .dropdown-menu {
  top: 45px;
  left: -140px;
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item {
  padding: 15px;
  border-bottom: 1px solid var(--color-gray-light);
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item span {
  display: inline-block;
  text-align: center;
  justify-content: center;
  width: 25px;
}

.mainbody .navbar .nav-item .dropdown-menu .dropdown-item:active,
.mainbody .navbar .nav-item .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  background-color: var(--color-red-light);
  color: var(--color-red);
}

/* =======================================================  
    21. C U S T O M I Z E D     F O R M    C H E C K S
========================================================== */
.form-check .form-check-input:checked {
  background-color: var(--color-red);
  border-color: var(--color-red);
}

.form-check .form-check-input[type="radio"] {
  float: right;
  margin-left: 0;
}

.form-check .form-check-label.adjust {
  margin-left: -20px;
}

/* =============================================  
    22. P A G I N A T I O N
================================================= */
.pagination {
  border: none;
}

.pagination .page-item {
  padding: 0px 4px;
}

.pagination .page-item.disabled .page-link {
  background-color: var(--alert-secondary);
}

.pagination .page-item.active .page-link {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-white);
}

.pagination .page-link {
  border-radius: 8px !important;
  color: var(--color-dark);
}

/* DROPDOWN SEARCH */
.dropdown-search {
  display: block;
  padding: 0px 15px;
  margin-bottom: 25px;
}

.dropdown-search .input-group .form-control {
  background-color: #e9ecef;
}

/* SEARCH INPUT */
.search-input {
  position: relative;
}

.search-input .search-suggestions {
  position: absolute;
  display: none;
  top: 52px;
  width: 100%;
  max-height: 190px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 0 0 12px 12px;
  overflow-y: auto;
  transition: 0.2 all ease-in-out;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}

.search-input.active .input-group .input-group-prepend .input-group-text {
  border-radius: 14px 0 0 0 !important;
}

.search-input.active .input-group .input-group-append .input-group-text {
  border-radius: 0 14px 0 0 !important;
}

.search-input.active .search-suggestions {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.search-input .search-suggestions .suggestion-item {
  position: relative;
  display: block;
  margin: 0 10px;
  border-radius: 12px;
  padding: 10px 20px;
  transition: 0.2 all ease-in-out;
  background-color: #fff;
}

.search-input .search-suggestions .suggestion-item:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

/* DASHBOARD CARD */
.dashboard-icon svg {
  font-size: 40px;
  color: var(--color-gray-light);
}

.dashboard-icon svg {
  font-size: 40px;
  color: var(--color-gray-light);
}

/* =====================================================  
    18. N A M E     C A R D
======================================================== */
.name-card {
  height: 40px;
  width: 40px;
  background-color: var(--color-red-light);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* table configuration */
.table thead tr {
  background: var(--dashboard-bg);
}

.table thead th {
  font-size: 12px;
  font-weight: 100;
}

/* ====================================================  
    23. P R O F I L E     C O N F I G U R A T I O N
======================================================= */
.profile-image {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--color-brown);
  display: flex;
  overflow: hidden;
}

.profile-image .profile-name-tag {
  display: block;
  color: var(--color-white);
  margin: auto;
  font-size: 22px;
  font-weight: var(--font-bold);
}

.profile-img-tag {
  position: absolute;
  top: 0px;
  left: 0px;
}

/* scroll tab */
.scrtabs-tab-scroll-arrow {
  display: none !important;
}

.scrtabs-tabs-fixed-container {
  width: 100% !important;
}

/* nav pills configuration */
.nav-pills .nav-link {
  color: var(--color-gray-dark) !important;
  font-weight: 300;
}

.nav-pills .nav-link.active {
  color: var(--color-red) !important;
  font-weight: 500;
  background-color: transparent;
  border-bottom: 2px solid var(--color-red);
  border-radius: 0%;
}

.country-dropdown {
  height: 500px;
  width: 350px;
  overflow-y: auto;
}
.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 350px) {
  .country-dropdown {
    height: 95vh;
    width: 95vw;
  }
}

.sidebar-nav .sidebar-item:last-of-type {
  bottom: 150px !important;
  position: inherit !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
}
.loading-overlay .ant-spin-dot-item {
  background-color: white;
}

.loading-overlay .ant-spin-text {
  color: white;
  font-size: 20px;
  font-weight: 700;
}
